import { useState, useEffect } from "react"
import { compoundQuery } from "../../../Firebase"
import useLoader from "../../../Context"
import { useNavigate } from "react-router-dom"
import { AddStudent } from "./AddStudent"
import { EditStudent } from "../../Common/EditStudent"



export const Students = () => {
    let loader = useLoader()
    const [data, setData] = useState([])
    const checkPaymentStatus = JSON.parse(localStorage.getItem("userData"));
    const navigate = useNavigate();

    async function getAllHomeData() {
        loader(true)
        try {

            let arr = [
                { key: 'role', operator: '==', value: 'children' },
                { key: 'tenant_id', operator: '==', value: Number(JSON.parse(localStorage.getItem('userData')).tenant_id) },
            ]
            let res = await compoundQuery("users", arr)
            setData(res)
            loader(false)
        }
        catch (err) {
            console.log(err)
            loader(false)
        }
    }
    useEffect(() => {
        getAllHomeData();
    }, [])


    return (
        <>

            {checkPaymentStatus.paymentStatus ? <AddStudent isExceed={data.length < checkPaymentStatus.totalUser} is_expire={new Date() > new Date(checkPaymentStatus.expire_at)} getAll={getAllHomeData} /> : null}

            <div className={`${!checkPaymentStatus.paymentStatus ? "h-100 d-flex align-items-center justify-content-center" : ""}`}>
                {checkPaymentStatus.paymentStatus ?
                    <div>
                        <div className="fs-2 mt-2" >All Students</div>

                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Child Name</th>
                                    <th>Grade</th>
                                    <th>email</th>
                                    <th>parent name</th>
                                    <th>Action</th>

                                </tr>
                            </thead>
                            <tbody>
                                {data.map((res, index) => (
                                    <tr key={index}>
                                        <td>{res.childName}</td>
                                        <td>{res.childBorn}</td>
                                        <td>{res.userEmail}</td>
                                        <td>{res.userName}</td>
                                        <td><EditStudent res={res} onSubmit={getAllHomeData} /></td>

                                        <td></td>
                                    </tr>
                                ))
                                }
                            </tbody>
                        </table>
                    </div>
                    :
                    <div className="text-center">
                        <div>
                            <div>You’re not subscribed to the program. Please purchase a plan to access the features. </div>
                            <br />
                            <div>
                                <button className="btn btn-primary" onClick={() => navigate("/admin/subscription")}> Purchase </button>
                            </div>

                        </div>

                    </div>}
            </div>
        </>
    )
}