import { createSlice } from "@reduxjs/toolkit";

let initialState = {
    backUrl: '',
    activityBackUrl:'',
    tobBarPath:[],
    midData:{
        text:'',
        img:''
    },
    cash:0
}

export const TopBarDataSlice = createSlice({
    name: 'topBarData',
    initialState,
    reducers: {
        updateTopBarData: (state, action) => {
            return ({ ...state, ...action.payload })
        }
    }
})
export const { updateTopBarData } = TopBarDataSlice.actions 