import { useEffect, useState } from "react";

import { getCollectionData, getMatchingData } from "../../Firebase";

import "./Timetable.css";
import { useNavigate } from "react-router-dom";
import { db } from "../../Firebase/firebase";
import { query, where, collection, onSnapshot } from "firebase/firestore";
import { useTranslation } from "react-i18next";
import { speak, translate } from "../../Assests/Speak";
// import { language_Translate } from "../../Assests/language";

export const Timetable = () => {
  const { t } = useTranslation();
  let [schedule, setSchedule] = useState([]);
  let [allSchedule, setAllSchedule] = useState([]);
  let hr = new Date().getHours();
  let navigate = useNavigate();
  let timer;
  const getSnapShotData = async (collectionName, key, operator, value) => {
    let userId = JSON.parse(localStorage.getItem("userData")).id;
    const dataQuery = query(
      collection(db, "children_schedule"),
      where("child_id", "==", userId)
    );

    onSnapshot(dataQuery, (snapShot) => {
      let data = snapShot.docs.map((doc) => doc.data());
      getTableDate(data);
    });
  };

  // console.log(allSchedule, "check");

  function convert24to12(state) {
    let data;

    if (state === "next") {
      data = (hr + 1) % 24;
    } else if (state === "prev") {
      data = (hr - 1 + 24) % 24;
    } else {
      data = hr;
    }

    let hours = data % 12 || 12; // Adjusting for 12-hour format

    if (data >= 12) {
      // console.log(`${hours} PM`);
      return `${hours} PM`;
    } else {
      // console.log(`${hours} AM`);
      return `${hours} AM`;
    }
  }
  const getTableDate = async (table) => {
    if (table && table.length !== 0) {
      setAllSchedule(table[0].child_schedule);
      // console.log(table);
      let arr = [];
      let value = table[0].child_schedule.map((e) => {
        let hour = e.time.split(" ")[0];
        let amPm = e.time.split(" ")[1];
        let time;
        if (amPm === "AM" || e.time === "12 PM") {
          time = Number(hour);
          // console.log(hour);
        } else {
          time = Number(hour) + 12;
          // console.log(hour);
        }
        arr.push(time);

        return { ...e, ...{ time24: time } };
      });
      const updatedData = value.map((entry) => {
        // Check if the entry's time is '12 AM'
        if (entry.time === "12 AM") {
          // Update the time24 property to 0
          return { ...entry, time24: 24 };
        }

        // If the time is not '12 AM', return the entry as is
        return entry;
      });
      // console.log(updatedData);
      let result = [];
      const adjustedHr = hr === 0 ? 24 : hr;
      // console.log(adjustedHr + 1 === 25 ? 1 : adjustedHr + 1);
      result[2] = updatedData.filter((e) => e.time24 === adjustedHr - 1)[0];
      result[1] = updatedData.filter((e) => e.time24 === adjustedHr)[0];
      result[0] = updatedData.filter(
        (e) => e.time24 === (adjustedHr + 1 === 25 ? 1 : adjustedHr + 1)
      )[0];
      setSchedule(result);
      // console.log(result);
    }
  };

  useEffect(() => {
    getSnapShotData();
    timer = setInterval(() => {
      let daa = new Date();
      if (daa.getHours() !== hr) {
        hr = daa.getHours();
        getSnapShotData();
      }
    }, 1000);
  }, []);

  // clearInterval(timer);

  return (
    <>
      <div className={"TimetableMain"}>
        {allSchedule.length !== 0 ? (
          <>
            <div
              className={"CardMainDiv mb-3"}
              // onClick={() => speak(schedule?.[2]?.name || "No schedule")}
              onClick={() =>{
                if(schedule?.[2]?.name !== undefined){
                  speak(translate(schedule?.[2]?.name, schedule?.[2]))
                }else{
                  speak('No Schedule')
                }
              }
                
              }
            >
              <img
                src={
                  schedule[2]
                    ? schedule[2].imgUrl
                    : "/images/currentNoSchedule.png"
                }
                alt=""
                className={"cardImage"}
              />
              <div className={"cardTime"}>
                <span> {t("previous.1")}</span>
                <span>
                  {schedule[2] ? schedule[2].time : convert24to12("prev")}
                </span>
              </div>
            </div>
            <div
              className={"CardMainDiv mb-3 "}
              // onClick={() => speak(schedule?.[1]?.name || "No schedule")}
              onClick={() =>{
                if(schedule?.[1]?.name !== undefined){
                  speak(translate(schedule?.[1]?.name, schedule?.[1]))
                }else{
                  speak('No Schedule')
                }
              }
                
              }
            >
              <img
                src={
                  schedule[1]
                    ? schedule[1].imgUrl
                    : "/images/currentNoSchedule.png"
                }
                alt=""
                className={"cardImage"}
              />
              <div className={"cardTime"}>
                <span>{t("current.1")}</span>
                <span>{schedule[1] ? schedule[1].time : convert24to12()}</span>
              </div>
            </div>
            <div
              className={"CardMainDiv "}
              // onClick={() => speak(schedule?.[0]?.name || "No schedule")}
              onClick={() =>{
                if(schedule?.[0]?.name !== undefined){
                  speak(translate(schedule?.[0]?.name, schedule?.[0]))
                }else{
                  speak('No Schedule')
                }
              }}
            >
              <img
                src={
                  schedule[0]
                    ? schedule[0].imgUrl
                    : "/images/currentNoSchedule.png"
                }
                alt=""
                className={"cardImage"}
              />
              <div className={"cardTime"}>
                <span>{t("upcoming.1")}</span>
                <span>
                  {schedule[0] ? schedule[0].time : convert24to12("next")}
                </span>
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              className={`${"CardMainDiv"} pointer`}
              onClick={() => navigate("/schedule")}
            >
              <img
                src="/images/noSchedule.jpg"
                alt=""
                className={"cardImage"}
              />
              <div className={`${"cardTime"} justify-content-center`}>
                <span>Create Schedule </span>
                <span></span>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
