import { createSlice } from "@reduxjs/toolkit";

let initialState={
}

export const AddCachedDataSlice=createSlice({
    name:"allCachedData",
    initialState,
    reducers:{
        addDataToCache:(state,action)=>{
           
            return ({...state,[action.payload.id]:action.payload.data})
        }
    }
})

export let {addDataToCache}=AddCachedDataSlice.actions