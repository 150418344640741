import { useEffect, useState } from "react"
import Sidebar from "../../Sidebar/Sidebar"
import style from "./dataReports.module.css"
import { compoundQuery, getCollectionData, getDocumentData, getMatchingData } from "../../../Firebase"
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Spinner, Table } from "reactstrap"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

const user = JSON.parse(localStorage.getItem('userData'))

export const DataAndReports = () => {
    const { t } = useTranslation()
    const [loader, setLoader] = useState(true)
    const [allMainFolders, setAllMainFolders] = useState([])
    const [allMainFoldersBackUp, setAllMainFoldersBackUp] = useState([])
    const [allActivities, setAllActivities] = useState([])
    const [allActivitiesBackUp, setAllActivitiesBackUp] = useState([])
    const [sequenceAct, setSequenceAct] = useState([])
    const [sequenceActBackUp, setSequenceActBackUp] = useState([])
    const userData = JSON.parse(localStorage.getItem('userData'))
    async function getAllSubSequences() {
        try {
            let res = await getCollectionData("home")
            let userLatestData = await getDocumentData("users", user.id)
            let ss = [...res]
            let newData = ss.filter(item => item.name !== 'Hoot Hoot' && item.name !== 'Fill Color Game' && item.name !== 'Matching Games' && item.name !== 'Tracing Game' && item.name !== 'Car Racing' && item.name !== 'Digital Books' && item.name !== 'Math Practice' && item.name !== 'Spelling Practice')
            let ff = newData.filter(res => res.defaultData == false && res.tenant_id == userData.tenant_id)
            let dd = newData.filter(res => res.defaultData == true)
            let newRes = await sequenceFunction([...ff, ...dd], userLatestData.homeClickSequence)
            setAllMainFolders(newRes)
            setAllMainFoldersBackUp(newRes)
            let data = await getMatchingData('homeSubFolders', 'type', '!=', 'folder')
            let data2 = await getCollectionData('sequenceSubFolder')
            setSequenceAct(data2)
            setSequenceActBackUp(data2)
            setAllActivities(data)
            setAllActivitiesBackUp(data)
            setLoader(false)
        }
        catch (err) {
            setLoader(false)
            console.log(err)
        }
    }
    useEffect(() => {
        getAllSubSequences()
    }, [])

    async function sequenceFunction(newData, ids) {
        // console.log(newData)
        let idd = ids == undefined ? [] : ids
        newData.sort((a, b) => {
            const indexA = idd.indexOf(a.id);
            const indexB = idd.indexOf(b.id);
            return indexA === -1 ? 1 : indexB === -1 ? -1 : indexA - indexB;
        });


        return newData
    }

    async function searchActivityByName(e) {
        let value = e.target.value
        let allHomeFolders = [...allMainFoldersBackUp]
        let sequence = [...sequenceActBackUp]
        let data = [...allActivities]
        let searchedData = data.filter(res => res.name.toLowerCase() == value.toLowerCase().trim() && res.name !== '')
        let dd = await filterDataById(searchedData)
        let newD = allHomeFolders.filter(res => dd.length == 0 && value.length == 0 ? res : dd.includes(res.id))
        let searchedDataS = sequence.filter(res => res.name.toLowerCase().includes(value.toLowerCase().trim()) && res.name !== '')
        if(searchedData.length !== 0){
            setAllMainFolders(newD)
        }else{
            if(value.length !== 0){
                setAllMainFolders(allHomeFolders.filter(res=>res.name == 'Sequence'))
            }else{
                setAllMainFolders(allMainFoldersBackUp)
            }
        }
    }

    async function filterDataById(iData) {
        let arr = iData.map(res => res.familyArray)
        return await arr.flat()
    }


    return (
        <>

            <Sidebar>
                <div className={style.Content}>
                    <div className={`${style.ContentHeadings} `}>{t("data_reports.1")}</div>
                    <form>
                        <div className="py-2">
                            <input type="search" className="form-control w-50" placeholder="Search Activity Name" required onChange={(e) => {
                                if (e.target.value == ' ') {
                                    e.target.value = ''
                                } else {
                                    searchActivityByName(e)
                                }
                            }} />
                        </div>
                    </form>
                    {loader ?
                        <div className=" h-75 d-flex justify-content-center align-items-center">
                            <Spinner size={"xl"} />
                        </div> :
                        <div className="mt-4">
                            {allMainFolders.map((res) => {
                                return (
                                    <AccordianComp data={{ ...res, dataType: 'card' }} />
                                )
                            })}
                        </div>
                    }
                </div>
            </Sidebar>
        </>

    )


function AccordianComp({ data }) {
    const { t } = useTranslation()

    const [open, setOpen] = useState();
    let [subFoldersData, setSubFoldersData] = useState([])
    let [isDataLoaded, setIsDataLoaded] = useState(false)
    let [loader, setLoader] = useState(false)
    let [noData, setNoData] = useState(false)
    let [allData, setAllData] = useState([])
    let [allDataBackUp, setAllDataBackUp] = useState([])


    async function sequenceFunction(newData, ids) {
        let idd = ids == undefined ? [] : ids
        newData.sort((a, b) => {
            const indexA = idd.indexOf(a.id);
            const indexB = idd.indexOf(b.id);
            return indexA === -1 ? 1 : indexB === -1 ? -1 : indexA - indexB;
        });


        return newData
    }

    async function getSubFolderData() {
        setLoader(true)
        try {
            if (data.name == "Sequence") {
                let res = await getCollectionData("sequence")
                let userLatestData = await getDocumentData("users", user.id)
                let newRes = await sequenceFunction(res, userLatestData.clickSequence)
                // newRes.sort((a, b) => b.clickedTime.seconds - a.clickedTime.seconds);
                setSubFoldersData(newRes)
                setAllData(res)
                setAllDataBackUp(newRes)
                if (res.length == 0) {
                    setNoData(true)
                }
                setIsDataLoaded(true)
                setLoader(false)
            }
            else {
                let res = await getMatchingData("homeSubFolders", "parentId", "==", data.id)
                let userLatestData = await getDocumentData("users", user.id)
                let newRes = await sequenceFunction(res, userLatestData.subHomeClickSequence)
                // newRes.sort((a, b) => b.clickedTime.seconds - a.clickedTime.seconds);
                setSubFoldersData(newRes)
                setAllData(res)
                setAllDataBackUp(newRes)
                setIsDataLoaded(true)
                if (res.length == 0) {
                    setNoData(true)
                }
                setLoader(false)
            }
        }
        catch (err) {
            console.log(err)
        }
    }
    let navigate = useNavigate()

    const toggle = (id) => {

        if (!isDataLoaded) {
            getSubFolderData()
        }
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };
   async function search(e) {
        let arr = [...allActivitiesBackUp,...sequenceActBackUp]
        let ff = await filterDataSubDataById([...allDataBackUp])
        let dd = arr.filter(r => r.name.toLowerCase().trim().includes(e.target.value.toLowerCase().trim()))
        // console.log(dd)
        const filteredData = dd.filter(item => 
            item.familyArray?.some(id => ff.includes(id)) || item.parentid
        );
        if(e.target.value.length !== 0){
            setSubFoldersData(filteredData)
        }else{
            setSubFoldersData(allData)
        }
    }


    // async function search(e) {
    //     let value = e.target.value
    //     let allSubHomeFolders = [...allDataBackUp]
    //     let data = [...allActivities]
    //     let searchedData = data.filter(res => res.name.toLowerCase() == value.toLowerCase().trim() && res.name !== '')
    //     let dd = await filterDataSubDataById(searchedData)
    //     let newD = allSubHomeFolders.filter(res => dd.length == 0 && value.length == 0 ? res : dd.includes(res.id) || res.name.toLowerCase() == value.toLowerCase().trim())
    //     setSubFoldersData(newD)
    // }

    async function filterDataSubDataById(iData) {
        let arr = iData.map(res => res.id)
        return await arr.flat()
    }


    return (
        <Accordion open={open} toggle={toggle} className="mt-3">
            <AccordionItem>
                <AccordionHeader targetId="1">{t(data.name)}</AccordionHeader>
                <AccordionBody accordionId="1">
                    {data.type == true ?
                        <>
                            <AccordianComp data={{ ...data, type: "folder", dataType: 'photo', name: 'Photo' }} />
                            <AccordianComp data={{ ...data, type: "folder", dataType: 'card', name: 'Photo and Word' }} />
                            <AccordianComp data={{ ...data, type: "folder", dataType: 'text', name: 'Word' }} />
                        </>
                        :
                        <>
                            {loader ?
                                <div className="text-center">
                                    <Spinner />
                                </div> :
                                <>
                                    {noData ? <div>No Data Available...</div> :
                                        <>
                                            <input type="text" onChange={search} placeholder="Search" className="form-control" />
                                            {subFoldersData.length == 0 ? <div className="ms-1 fw-bold mt-3">No Search Found...</div> :
                                                <>
                                                    {subFoldersData.map((res) => {
                                                        return (
                                                            <>
                                                                {res.type === "folder" ?
                                                                    <AccordianComp data={{ ...res, dataType: 'card' }} /> :
                                                                    <div className="border mt-2 p-2 d-flex justify-content-between">
                                                                        {res.name !== "" ? <div>{res.name}</div> : <img style={{ width: '25px', height: '25px' }} src={res.imgUrl} />}
                                                                        <div><span className="pointer" onClick={() => {
                                                                           if(data.name == "Sequence"){
                                                                            navigate(`viewDtd/${res.id}/${data.dataType}`)
                                                                           }else{
                                                                            if(res.path.includes('Sight words')){
                                                                                navigate(`viewDtd/${res.id}/${res?.type}`)
                                                                            }
                                                                            else if(res.path.includes('Actions & Verbs')){
                                                                                navigate(`viewDtd/${res.id}/${res?.type}`)
                                                                            }
                                                                            else{
                                                                                navigate(`viewDtd/${res.id}/${data.dataType}`)
                                                                            }
                                                                        }
                                                                            // if (res.path.includes('/A,B,C/') || res.path.includes('/1 2 3/') || res.path.includes('/Number') || res.path.includes('/Odd') || res.path.includes('/Even')) {
                                                                            //     navigate(`viewDtd/${res.id}/${data.dataType}`)
                                                                            // } else {
                                                                            //     navigate(`view/${res.id}/${data.dataType}`)
                                                                            // }
                                                                        }}>View</span></div>
                                                                    </div>
                                                                }
                                                            </>
                                                        )
                                                    })}

                                                </>

                                            }
                                        </>
                                    }
                                </>
                            }
                        </>}


                </AccordionBody>
            </AccordionItem>

        </Accordion>
    )
}

}