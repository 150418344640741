import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getMatchingData } from "../../Firebase";

let initialState = {
    allData: [],
    isShow: true
}

function getHomeNestedData() {

}

export const getHomePageInnerFolderData = createAsyncThunk("getAllNestedHomeData", async (id, { rejectWithValue }) => {
    console.log(id)

    try {
        let res = await getMatchingData("homeSubFolders", "familyArray", "array-contains", id)
        return res
    }
    catch (err) {
        return rejectWithValue(err)
    }
})


export const HomePageViewAllDataSlice = createSlice({
    name: 'homePageData',
    initialState,
    reducers: {
        viewAllBtnToogle: (state, action) => {
            return ({ ...state, isShow: action.payload })
        },
        emptyHomePageNestedData:(state,action)=>{
            return ({...state,allData:[]})
        }

    },
    extraReducers:(builder)=>{
        builder.addCase(getHomePageInnerFolderData.fulfilled,(state,action)=>{
            return ({...state,allData:action.payload})
        })
    }
})
export const { viewAllBtnToogle,emptyHomePageNestedData } = HomePageViewAllDataSlice.actions