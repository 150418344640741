import React, { useEffect, useState } from 'react'
const audio = new Audio("/audios/bgmusicapp.mp3");
// const [audio] = new Audio("/audios/bgmusicapp.mp3")
export default function AudioBackgroun(play,pause) {

    useEffect(() => {
      const playAudio = async () => {
        try {
          // Check if the document is visible before attempting autoplay
          if (!document.hidden) {
            if(play){

              await audio.play();
            }else{
               audio.pause();

            }
            audio.muted = false;
            // console.log("Audio played successfully");
          } else {
            // console.log("Document is not visible, audio autoplay prevented.");
          }
        } catch (error) {
          console.error('Autoplay prevented:', error);
        }
      };
  
      // Attempt autoplay when the component mounts
      playAudio();
  
      // Add event listener for visibility change
      const handleVisibilityChange = () => {
        if (!document.hidden) {
          playAudio();
        }
      };
  
      document.addEventListener('visibilitychange', handleVisibilityChange);
  
      // Clean up event listeners on component unmount
      return () => {
        document.removeEventListener('visibilitychange', handleVisibilityChange);
        // You may want to remove other event listeners if you have them
      };
    }, [audio]);


  return (
    <></>
  )
}
// export async function audioPlay(){

// console.log(audio)

//   audio.loop = true
//   await audio.play();
//   audio.muted = false;

// }