import { useState } from "react";
import axios from "axios";
var lang = "en-US";

export const speak = (text) => {
  if ("speechSynthesis" in window) {
    var voices = window.speechSynthesis.getVoices();
    // var voice = voices.find(v => v.name === lang);
    // Selecting the third voice in the voice list

    // console.log(voice);

    var speech = new SpeechSynthesisUtterance();
    // speech.voice = voice; // Uncomment this if you want to set the voice
    speech.text = text;
    speech.lang = lang;
    // console.log(speech);
    window.speechSynthesis.speak(speech);
  } else {
    // console.error("Speech synthesis not supported");
  }
};

export const changeSpeak = async (lng) => {
  lang = lng;
};

export const translate = (text, data) => {
  // console.log(text);
  // console.log(data);
  if (data && data.languageNames) {
    let langData = data.languageNames;
    // console.log(langData);
    return langData[lang];
  } else {
    return text;
  }
};

export const translate_google = async (text) => {
  let langCode = lang.split("-")[0];
  // console.log(1);
  try {
    const response = await axios.post(
      "https://translation.googleapis.com/language/translate/v2",
      {},
      {
        params: {
          q: text,
          target: langCode,
          key: "AIzaSyBA08A0ON-6P3C9V4tuV26GOika3o5FpcU",
        },
      }
    );

    return response.data.data.translations[0].translatedText;
  } catch (error) {
    // console.error("Error during translation", error);
  }
};
