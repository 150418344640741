import { useState, useEffect } from "react"
import { getMatchingData } from "../../../Firebase"
import useLoader from "../../../Context"
import { DeleteStudent } from "../../../Utilities/DeleteUsers"
import { EditStudent } from "../../Common/EditStudent"


export const StudenListing = () => {



    let loader = useLoader()

    const [data, setData] = useState([])
    let [allSchool, setAllSchool] = useState([])
    let [allData, setAllData] = useState([])

    async function getAllHomeData() {
        loader(true)
        try {


            let res = await getMatchingData("users", "role", "==", "children")
            let adminData = await getMatchingData("users", "role", "==", "admin")
            setAllSchool(adminData)

            setData(res)

            setAllData(res)
            loader(false)
        }
        catch (err) {
            console.log(err)
            loader(false)
        }
    }




    function DeleteStudentById({ id }) {


        async function deleteData(id) {

            await DeleteStudent(id)

            await getAllHomeData();
        }

        return (
            <div>
                <i onClick={() => deleteData(id)} class="bi bi-trash"></i>

            </div>
        )
    }

    useEffect(() => {
        getAllHomeData();

    }, [])

    async function getSchoolData(e) {
        let array = [...allData]
        if (e.target.value == 'All') {
            setData(array)
        } else if (e.target.value === 'Individual') {
            setData(array.filter(element => element.plan == 'Individual'))
        }


        else {
            setData(array.filter(element => element.tenant_id == e.target.value))
        }
    }


    return (
        <>

            <div className="d-flex justify-content-between align-items-center pe-3">


                <div className="fs-2 mt-2" >All Students</div>



                <div>
                    <select className="form-select" onChange={getSchoolData}>
                        <option value='All'>All Students</option>
                        <option value='Individual'>Individual</option>
                        {allSchool.map(res => {
                            return (
                                <option value={res.tenant_id}>{res.center_name}</option>
                            )
                        })}
                    </select>
                </div>




            </div>
            <div>
                <table class="table">
                    <thead>
                        <tr>
                            <th>Child Name</th>
                            <th>Student Grade</th>
                            <th>Email</th>
                            <th>Parent name</th>
                            <th>School name</th>
                            <th>Plan</th>
                            <th>Action</th>

                        </tr>
                    </thead>
                    <tbody>
                        {data.map((res, index) => (
                            <tr key={index}>
                                <td>{res.childName}</td>
                                <td>{res.childBorn}</td>
                                <td>{res.userEmail}</td>
                                <td>{res.userName}</td>
                                <td>{res.center_name || "--no school--"}</td>
                                <td>{res.plan}</td>
                                <td className="d-flex gap-4">

                                    <DeleteStudentById id={res.id} />
                                    <EditStudent res={res} onSubmit={getAllHomeData} />


                                </td>



                                <td></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    )

}


