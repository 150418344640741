import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getCollectionData } from "../../Firebase"


let initialState = {
    allHomeData: [],
    indexing:[]
}
export const getAllHomeDataThunk = createAsyncThunk("getAllHomeData",async (data,{rejectWithValue}) => {
    try {
        let res= await getCollectionData("home")
        return res

    }
    catch (err) {
        return rejectWithValue(err)
    }
})
export const getHomeIndexing = createAsyncThunk("getHomeIndexing",async (data,{rejectWithValue}) => {
    try {
        let res= await getCollectionData("indexing")
        return res[0].homeIndexing

    }
    catch (err) {
        return rejectWithValue(err)
    }
})


export const HomePageDataSlice = createSlice({
    name: 'homePageDataSlice',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(getAllHomeDataThunk.fulfilled, (state, action) => {
            return ({ ...state, allHomeData: action.payload })
        })
        builder.addCase(getAllHomeDataThunk.rejected, (state, action) => {
            return state
        })
        builder.addCase(getHomeIndexing.fulfilled, (state, action) => {
            return ({ ...state, indexing: action.payload })
        })
        builder.addCase(getHomeIndexing.rejected, (state, action) => {
            return state
        })
    }
})