import React, { useState, useEffect, useRef } from "react";
import HTMLFlipBook from "react-pageflip";
import { pdfjs, Document, Page as ReactPdfPage } from "react-pdf";
import style from "./style.module.css"
import { Link } from "react-router-dom";
import { useParams, useNavigate } from 'react-router-dom'
import { getDocumentData } from "../../Firebase";
import { useSound } from "use-sound";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const width = 300;
const height = 500;


export const Book = () => {
    const [isMusicOn, setIsMusicOn] = useState(true);

    var musicaa =
    "https://firebasestorage.googleapis.com/v0/b/verbal-autism-testing.appspot.com/o/bgmusicapp.mp3?alt=media&token=3c7f9e5f-a38d-48fe-9044-8ad1a8544cb4";
    const [playSound, { stop }] = useSound(musicaa, { volume: 0.03, loop: true });

  useEffect(() => {
    playSound();
    return () => {
      stop();
    };
  }, [playSound, stop]);
    const { id } = useParams()
    const { name } = useParams()
    const [numPages, setNumPages] = useState(null);
    const [pdfLoaded, setPdfLoaded] = useState(false);
    const [pdfData, setPdfData] = useState(null);
    let pagesRef = useRef(null)

    const [bookDimensions, setBookDimensions] = useState({
        width: 450,
        height: 650
    })
    const Page = React.forwardRef(({ pageNumber }, ref) => {
        return (
            <div ref={ref} className="page">
                <ReactPdfPage pageNumber={pageNumber} width={bookDimensions.width} />
            </div>
        );
    });

    async function getPdf() {

        let res = await getDocumentData('digitalBook', name)
        // console.log(res,"ppppppppp")
        // console.log(res)
        // const pdfUrl =
        //     "https://firebasestorage.googleapis.com/v0/b/verbal-autism-testing.appspot.com/o/002-GINGER-THE-GIRAFFE-Free-Childrens-Book-By-Monkey-Pen.pdf?alt=media&token=99680d59-3f40-4cc2-95bd-d3779806b07d";
        let pdfUrl = res?.bookPdfUrl
        // console.log(pdfUrl)
        await fetch(pdfUrl)
            .then((response) => response.arrayBuffer())
            .then((data) => {
                setPdfData(Array.from(new Uint8Array(data)));
            })
            .catch((error) => console.error("Error fetching PDF:", error));
    }


    useEffect(() => {
        setNumPages(null);
        setPdfLoaded(false);
        setPdfData(null);
        getPdf()




        // return () => {
        //     setNumPages(null);
        //     setPdfLoaded(false);
        //     setPdfData(null);
        // };
    }, []);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
        // console.log(numPages);
        setPdfLoaded(true);
    };

    const renderPdfPages = () => {
        const pages = [];
        for (let pageNumber = 1; pageNumber <= numPages; pageNumber++) {
            pages.push(<Page key={pageNumber} pageNumber={pageNumber} />);
        }
        return pages;
    };


    useEffect(() => {
        window.addEventListener("resize", () => {
            // console.log(window.innerWidth)
            // if (window.innerWidth < 1410) {
            //     setBookDimensions({
            //         width: 600,
            //         height: 100
            //     })
            // }
            //     if(window.innerWidth<1405){
            // setBookDimensions({
            //     width:600,
            //     height:1200
            // })
            //  }
            //     if(window.innerWidth<1205){
            // setBookDimensions({
            //     width:500,
            //     height:800
            // })
            //  }
            //     if(window.innerWidth<1005){
            // setBookDimensions({
            //     width:400,
            //     height:600
            // })
            //  }
            //     if(window.innerWidth<805){
            // setBookDimensions({
            //     width:300,
            //     height:400
            // })
            //  }
            //     if(window.innerWidth<605){
            // setBookDimensions({
            //     width:200,
            //     height:400
            // })
            //  }
        })
    }, [bookDimensions, pdfData])

    return (
        <>
            <div className={`${style.bgImg}`}>
            <div className={`${style.pdf_container} p-4`}>
                <div className="mb-3 d-flex">
                    <Link to={`/BooksListing/${id}`}>
                        <img src="/images/back.png" width='40px' />
                    </Link>
                    <span className="ms-3">
                  {isMusicOn ? (
                    <img
                      src="/images/MusicOn.png"
                      className="pointer"
                      width='40px'
                      onClick={() => {
                        setIsMusicOn(!isMusicOn)
                        stop();
                      }}
                      alt="Music On"
                    />
                  ) : (
                    <img
                      src="/images/MusicOff.png"
                      className="pointer"
                      width='40px'
                      onClick={() => {
                        setIsMusicOn(!isMusicOn)
                        playSound();
                      }}
                      alt="Music Off"
                    />
                  )}
                </span>
                </div>
                <div className="">
                    <div className="w-100">
                        {pdfData && (
                            <Document
                                file={{ data: pdfData }}
                                onLoadSuccess={onDocumentLoadSuccess}
                            >
                                {pdfLoaded && (
                                    <HTMLFlipBook
                                        width={bookDimensions.width}
                                        height={bookDimensions.height}
                                        ref={(component) => (pagesRef.current = component)}>
                                        {renderPdfPages()}
                                    </HTMLFlipBook>
                                )}
                            </Document>
                        )}
                    </div>
                </div>

            </div>
            <div className="d-flex justify-content-center gap-5 mt-2">
                <button className="btn btn-primary" onClick={() => pagesRef.current.pageFlip().flipPrev()}><i class="bi bi-arrow-left fs-2"></i>&nbsp;&nbsp;</button>

                <button className="btn btn-primary" onClick={() => pagesRef.current.pageFlip().flipNext()}>&nbsp;&nbsp;<i class="bi bi-arrow-right fs-2"></i></button>
            </div>
            </div>

        </>
    );
};
