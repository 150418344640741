import React, { useEffect, useRef, useState } from 'react'
import "./mathsPractice.css"
import { useLocation, useNavigate } from 'react-router-dom'
import { useSound } from "use-sound";

export const MathsPractice = () => {
    var musicaa = 'https://firebasestorage.googleapis.com/v0/b/verbal-autism-c8d22.appspot.com/o/colorGameBg.mp3?alt=media&token=0f727154-b151-4cef-ad95-e26c4dca39a2'

    const [playSound, { stop }] = useSound(musicaa, { volume: 0.03, loop: true });

    useEffect(() => {
        playSound()
        return () => {
            stop()
        };
    }, [playSound, stop]);

    const dd = useLocation().search;
    let mode = new URLSearchParams(dd).get('mode');
    let gradeLevel = new URLSearchParams(dd).get('gradeLevel');
    let navigate = useNavigate()
    let [numberOne, setNumberOne] = useState(0)
    const [isMusicOn, setIsMusicOn] = useState(true);
    let [numberTwo, setNumberTwo] = useState(0)
    let maxNumber = useRef(getMaxAccordingToGrade().max)
    let minNumber = useRef(getMaxAccordingToGrade().min)
    let [optionsArray, setOptionsArray] = useState([])
    let operator = useRef(mode === "add" ? "+" : mode === "sub" ? "-" : "*")
    let [emoji, setEmoji] = useState(3)

    function generateRandomNumber() {
        let num1 = Math.floor(Math.random() * (maxNumber.current - minNumber.current + 1)) + minNumber.current;
        let num2 = Math.floor(Math.random() * (maxNumber.current - minNumber.current + 1)) + minNumber.current;

        if (num1 === num2) {
            generateRandomNumber()
            return
        }
        if (operator.current === "-" && num1 <= num2) {
            generateRandomNumber()
            return
        }
        if (operator.current == "/") {
            console.log(num1, "{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{[[")
            console.log(num2, "}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}")
        }
        //     // generateRandomNumber()
        //     // return
        // }
        else {
            generateRandomOptions(eval(num1 + operator.current + num2))
            setNumberOne(num1)
            setNumberTwo(num2)
        }
    }

    function getMaxAccordingToGrade() {
        switch (gradeLevel) {
            case "one":
                return { max: 5, min: 1 }
                break;
            case "two":
                return { max: 6, min: 1 }
                break;
            case "three":
                return { max: 9, min: 3 }
                break;
            case "four":
                return { max: 10, min: 5 }
                break;
            case "five":
                return { max: 11, min: 5 }
                break;
            case "six":
                return { max: 12, min: 5 }
                break;
            case "seven":
                return { max: 13, min: 5 }
                break;
            case "eight":
                return { max: 14, min: 5 }
                break;
            case "nine":
                return { max: 15, min: 5 }
                break;
            case "ten":
                return { max: 16, min: 5 }
                break;
            case "eleven":
                return { max: 17, min: 5 }
                break;
            case "twelve":
                return { max: 18, min: 5 }
                break;
            case "thirteen":
                return { max: 18, min: 5 }
                break;

            default:
                break;
        }
    }



    function shuffleArray(array) {
        let newArr = [...array]
        for (let i = newArr.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [newArr[i], newArr[j]] = [newArr[j], newArr[i]];
        }
        return newArr
    }

    function generateRandomOptions(correctAnswer) {
        let num1 = Math.floor(Math.random() * (maxNumber.current - minNumber.current + 1)) + minNumber.current;
        let num2 = Math.floor(Math.random() * (maxNumber.current - minNumber.current + 1)) + minNumber.current;
        if (num1 === num2 || num1 === correctAnswer || num2 === correctAnswer) {
            generateRandomOptions(correctAnswer)
        }
        else {
            setOptionsArray(shuffleArray([num1, num2, correctAnswer]))
            setEmoji(Math.floor(Math.random() * (10 - 1 + 1)) + 1)
        }
    }

    useEffect(() => {
        generateRandomNumber()
    }, [])


    function optionClick(opt, e, index) {
        if (opt === (eval(numberOne + operator.current + numberTwo))) {
            generateRandomNumber()
        }
        else {
            // console.log(e.target.parentElement)
            let element = e.target
            if (e.target.tagName === "IMG") {
                element = e.target.parentElement.parentElement
            }
            console.log(element)
            element.classList.add("mathsPracticeShuffle")
            setTimeout(() => {
                element.classList.remove("mathsPracticeShuffle")
            }, 500);
        }
    }

    function Emoji() {
        return (
            <img src={`/images/mathsPracticeEmojis/mathsPracticeEmoji${emoji}.png`} className='emoji_img' />
        )
    }

    return (

        <div className={"mathsPractice_con"}>
           <div className='d-flex justify-content-between align-items-center'>
           <div className={"back_btn"} onClick={() => navigate(`/mathsPracticeCards?gradeLevel=${gradeLevel}`)}></div>
            <span className="mt-3 me-3">
                  {isMusicOn ? (
                    <img
                      src="/images/MusicOn.png"
                      className="pointer"
                      width='40px'
                      onClick={() => {
                        setIsMusicOn(!isMusicOn)
                        stop();
                      }}
                      alt="Music On"
                    />
                  ) : (
                    <img
                      src="/images/MusicOff.png"
                      className="pointer"
                      width='40px'
                      onClick={() => {
                        setIsMusicOn(!isMusicOn)
                        playSound();
                      }}
                      alt="Music Off"
                    />
                  )}
                </span>
           </div>
            <div className={`row align-items-center p-0 numbers_con`}>

                {mode !== "mul" ?
                    <>
                        <div className={`col-3 p-3 d-flex flex-wrap justify-content-center gap-3`}>
                            {[...Array(numberOne)].map(() => {
                                return (
                                    <Emoji />
                                )
                            })}
                        </div>
                        <div className='col-1'>{operator.current}</div>

                        <div className={`col-3 p-3 d-flex flex-wrap justify-content-center gap-3`}>
                            {[...Array(numberTwo)].map((res) => {
                                return (
                                    <Emoji />
                                )
                            })}
                        </div>
                        <div className='col-1'>=</div>

                        <div className='col-4  p-3 d-flex flex-wrap gap-3'>
                            ?
                        </div>
                    </> : null}



                <div className='col-3 text-center'>{numberOne}</div>
                <div className='col-1'>{operator.current}</div>
                <div className='col-3 text-center'>{numberTwo}</div>
                <div className='col-1'>=</div>
                <div className='col-4'>?</div>

            </div>
            <div className='choose_text text-center'>Click on the correct option</div>

            <div className={`option_cards_con`}>
                {optionsArray.map((res, index) => {
                    return (
                        <>
                            <div className='option_card'>
                                <div className={`${"box_wraper w-100"}`} onClick={(e) => optionClick(res, e, index)}>
                                    {mode === "mul" ?
                                        <>
                                            <div></div>
                                            <div></div>
                                            <div className='fs-1'>{res}</div>
                                            <div></div>
                                            <div></div>
                                        </>
                                        :
                                        <>
                                            {[...Array(res)].map((res2) => {
                                                return (
                                                    <div style={{ height: 'fit-content' }} className='d-flex justify-content-center align-items-center'>
                                                        <Emoji />
                                                    </div>
                                                )
                                            })}
                                        </>}

                                </div>
                                {mode === 'mul'?"":<div className='text-center fs-1 mt-2 w-100'>{[...Array(res)].length}</div>}
                               
                            </div>
                        </>

                    )
                })}

            </div>


        </div>

    )

}
