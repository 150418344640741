import { configureStore } from "@reduxjs/toolkit";
import { TopBarDataSlice } from "./Slices/TopBarDataSlice";
import { HomePageViewAllDataSlice } from "./Slices/HomePageViewAllDataSlice";
import { SequenceDataSlice } from "./Slices/SequenceSlices";
import { HomePageDataSlice } from "./Slices/HomePageDataSlice";
import { AutismTopBarDataSlice } from "./Slices/VerbalAutismTopbar";
import { AddCachedDataSlice } from "./Slices/AllCachedDataSlice";


export const store=configureStore({
    reducer:{
        topBarData:TopBarDataSlice.reducer,
        homePageViewAllData:HomePageViewAllDataSlice.reducer,
        sequenceData:SequenceDataSlice.reducer,
        homeAllData:HomePageDataSlice.reducer,
        autismTopbardata:AutismTopBarDataSlice.reducer,
        allCachedData:AddCachedDataSlice.reducer
    }
})