import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { getDocumentData } from './Firebase/CloudFirestore/GetData';
import i18next from 'i18next';
import { changeSpeak } from './Assests/Speak';
const fallbackLng = ['en'];
const availableLanguages = ['en', 'ar', 'es', 'fa', 'fr', 'hi', 'it', 'ja', 'zh', 'de'];

const options = {
  // order and from where user language should be detected
  order: ['navigator', 'htmlTag', 'path', 'subdomain'],

  // keys or params to lookup language from
  lookupQuerystring: 'lng',
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  // cache user language on
  caches: ['localStorage', 'cookie'],
  excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

  // optional expire and domain for set cookie
  cookieMinutes: 10,
  cookieDomain: 'myDomain',

  // optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement,

  // only detect languages that are in the whitelist
  checkWhitelist: true
}

i18n
  .use(Backend) // load translation using xhr -> see public/locales. We will add locales in the next step

  .use(LanguageDetector) // detect user language

  .use(initReactI18next) // pass the i18n instance to react-i18next.

  .init({
    fallbackLng, // if user computer language is not on the list of available languages, than we will be using the fallback language specified earlier
    debug: false,
    whitelist: availableLanguages,
    detection: options,

    interpolation: {
      escapeValue: false
    },
  });

const languageChange = async () => {
  const Data = JSON.parse(localStorage.getItem("userData"));
  // console.log(123)
  if (Data && Data.id) {
    let res = await getDocumentData("users", Data.id);
    if (res?.language) {

      i18next.changeLanguage(res.language.code);
      changeSpeak(res.language.speakCode);
    }
  }
}
languageChange()
export default i18n;