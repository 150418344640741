import { createSlice } from "@reduxjs/toolkit";

let initialState = {
    cardLimit:3,
    sequenceData:[],
    sequenceId:[]
}

export const SequenceDataSlice = createSlice({
    name: 'squenceSlice',
    initialState,
    reducers: {
        updateSequenceData: (state, action) => {
            // console.log(action.payload)
            // return
            return ({ ...state, ...action.payload })
        }
    }
})
export const { updateSequenceData } = SequenceDataSlice.actions 