import { createSlice } from "@reduxjs/toolkit";

let initialState = {
    playSentance:'I',
    SentanceImgArr:[],
    playSentanceByRoute:'',
    playSentanceByRouteBackUp:'',
    lastImgUrl:[]
}

export const AutismTopBarDataSlice = createSlice({
    name: 'topBarData',
    initialState,
    reducers: {
        updateAutismTopBarData: (state, action) => {
            return ({ ...state, ...action.payload })
        }
    }
})
export const { updateAutismTopBarData } = AutismTopBarDataSlice.actions 