import React, { useContext, useEffect, Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { Router } from "./Router";
import './style.css'
import { MyProvider } from "./Context";
import { Provider } from "react-redux";
import { store } from "./Store/Store";
import './i18n';

export default function App() {
  localStorage.setItem("play",2);
  
  const onTouchStarthandle = (e) => {
    e.stopPropagation();
    e.preventDefault();
    e.bubbles = false;
  };

  useEffect(() => {
    // Add event listener to the contextmenu event
    const handleContextMenu = (e) => {
      e.preventDefault();
    };

    document.addEventListener('contextmenu', handleContextMenu);

    // Remove event listener when the component is unmounted
    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []); // Empty dependency array ensures that the effect runs only once, similar to componentDidMount
  const containerStyle = {
    userSelect: 'none', // Prevent text selection
  };

  return(
<div>
    Testing

    </div>
  
  )
  
  return (
    
    // <I18nextProvider i18n={i18n}>
    <>
    <div className="load_font">acheck</div>
    {/* <ReactAudioPlayer src="/audios/bgmusicapp.mp3"/> */}

    {/* <audio>
      <source src="/audios/bgmusicapp.mp3"/>
    </audio> */}
      <Provider store={store} onTouchStart={onTouchStarthandle}>
        <MyProvider>
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </MyProvider>
      </Provider>
      </>
    // </I18nextProvider>
  );
}
